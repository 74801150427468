<template>
  <div>
    <el-upload :multiple="multiple" :action="uploadAction" :on-success="headImageSuccess" :before-upload="beforeheadImageUpload" :show-file-list="false" :on-progress="headImageProgress">
      <div class="img-box">
        <!--单图回显-->
        <div v-if="imgUrl && !multiple" class="multipleImgs">
          <el-image
            class="avatar"
            style="width: 178px; height: 178px"
            :src="imgUrl"
          />
          <div class="zhezhao">
            <span @click.stop="singleRemoveImg">
              <i class="el-icon-delete" />
            </span>
            <span @click.stop="zoomIn(imgUrl)">
              <i class="el-icon-zoom-in" />
            </span>
          </div>
        </div>

        <i v-if="!multiple && !imgUrl" class="el-icon-plus avatar-uploader-icon" />
      </div>
      <div class="img-box">
        <!--多图回显-->
        <div v-for="(item, index) in multipleImgsUrl" v-if="multiple && multipleImgsUrl.length" class="multipleImgs">
          <el-image
            :key="index"
            class="avatar"
            style="width: 178px; height: 178px"
            :src="item"
            :preview-src-list="multipleImgsUrl"
          />
          <div class="zhezhao">
            <span @click.stop="removeImg(index)">
              <i class="el-icon-delete" />
            </span>
            <span @click.stop="zoomIn(item, index)">
              <i class="el-icon-zoom-in" />
            </span>
          </div>
        </div>
        <i v-if="multiple && multipleImgsUrl.length < 10 " class="el-icon-plus avatar-uploader-icon" />
      </div>
      <div v-if="(!imgUrl && headImageLoading)" class="upload-box">
        <i class="el-icon-loading" />
        <div class="text">上传中</div>
      </div>

      <!--        <div slot="tip" class="el-upload__tip">（请上传JPG、PNG格式图片，图片大小在10M以内）</div>-->
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import config from '@/api/config'
export default {
  name: 'Upload',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    singleImgUrl: {
      type: String,
      default: ''
    },
    multiplesImgsUrl: {
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      uploadAction: config.File_HOST,
      imgUrl: '', // 上传成功的图片地址,
      multipleImgsUrl: [],
      headImageLoading: false,
      count: 0,
      index: '',
      dialogVisible: false,
      dialogImageUrl: '',
      arr: []
    }
  },
  watch: {
    singleImgUrl(newValue) {
      this.imgUrl = newValue
    },
    multiplesImgsUrl: {
      deep: true,
      handler(newValue) {
        this.arr = JSON.parse(JSON.stringify(newValue))
        this.multipleImgsUrl = newValue
      }
    }
  },
  methods: {
    // 单图删除
    singleRemoveImg() {
      this.imgUrl = ''
      this.$emit('getImgUrl', { type: this.type, img: this.imgUrl })
    },
    // 放大
    zoomIn(item, index) {
      this.dialogVisible = true
      this.dialogImageUrl = item
    },
    // 删除图片
    removeImg(index) {
      this.multipleImgsUrl.splice(index, 1)
    },
    // 封面上传成功
    headImageSuccess(res) {
      this.headImageLoading = false
      if (this.multiple) {
        if (this.multipleImgsUrl.length < 10) {
          this.multipleImgsUrl.push(res.data)
          this.$emit('getImgUrl', { type: this.type, img: this.multipleImgsUrl })
        }
      } else {
        this.imgUrl = res.data
        this.$emit('getImgUrl', { type: this.type, img: this.imgUrl })
      }
    },
    beforeheadImageUpload(file) {
      if (this.multipleImgsUrl.length >= 10) {
        if (document.getElementsByClassName('el-message').length === 0) { // 只提醒一次
          this.$message.warning('最多上传10张图片')
        }
        this.headImageLoading = false
        return false
      }
      const REG_PHOTO = /^(jpg|png|jpeg)$/
      const name = file.name
        .split('.')
        .pop()
        .toLowerCase()
      if (!REG_PHOTO.test(name)) {
        this.$message.error('封面格式不正确，支持jpg,png,jpeg格式的文件')

        return false
      }
      const isLt100KB = file.size <= 10485760
      if (!isLt100KB) {
        this.$message.error('图片大小不可超过10M')
        return false
      }
    },
    // 封面上传中
    headImageProgress(event, file) {
      this.headImageLoading = true
    }
  }
}
</script>

<style lang="scss" scoped>
   /deep/ .el-upload {

        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .img-box {
        display: flex;
        flex-wrap: wrap;

        img {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            border: 1px dashed #d9d9d9 !important;
        }
        i {
            border: 1px dashed #d9d9d9 !important;
        }

    }

    .multipleImgs {
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;
        .zhezhao {
            width: 178px;
            height: 178px;
            background-color: rgba(0, 0, 0 ,.3);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
            line-height: 178px;
            span {
                display: inline-block;
                width: 50%;
                cursor: pointer;
                i {
                    border: none !important;
                    font-size: 24px;
                    color: #FFFFFF;
                }
            }

        }
    }

    .multipleImgs:hover .zhezhao {
        opacity: 1; //设置为完全不透明，相当于把这个盒子显示出来
        cursor: default;  //默认正常鼠标指针
    }

</style>
